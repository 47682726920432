const skills = [
  "Agile/Scrum Methodology",
  "Asana",
  "AWS",
  "AWS EC2",
  "Babel",
  "Bootstrap",
  "Chakra UI",
  "Circle CI",
  "CodeIgniter",
  "Confluence",
  "CSS3",
  "Django",
  "Docker",
  "Express.js",
  "Flask",
  "Git",
  "GitHub",
  "GraphQL",
  "Hacklang",
  "HTML5",
  "JavaScript",
  "Jest",
  "JIRA",
  "Material UI",
  "Mercurial",
  "MongoDB",
  "MVC Architecture",
  "MySQL",
  "Next.js",
  "Node.js",
  "PHP",
  "Pixel-Perfect Components",
  "PostgreSQL",
  "Python",
  "React Testing Library",
  "React.js",
  "Redux",
  "Relay",
  "RSpec",
  "Ruby",
  "Ruby on Rails",
  "Ruby Version Manager",
  "Sass/Less/Scss",
  "Sidekiq",
  "Slack",
  "Software Development Life Cycle (SDLC)",
  "Storybook",
  "Tailwind CSS",
  "Technical Documentation",
  "Test Driven Development",
  "TypeScript",
  "Vite",
  "Vue.js",
];

export default skills;
